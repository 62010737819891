<template>


  <div :class="'wdg ' + type">

    <div class="hd">
      <div class="nf">

        <div class="hnt">
          GrowDIaries Loyalty & Trust Program
        </div>
        <h1 class="ttl">
          Behind every review, there is an experience that matters
        </h1>

      </div>

      <img src="/images/widget/hero_img.png" alt="" class="psn">

    </div>


    <!-- FORM -->


    <div class="widget_form">
      <div class="ttl">
        Get your widget - easy steps
      </div>

      <div class="rw">
        <div class="cl">

          <!-- COLOR -->
          <div class="pos" data-setup="color">
            <div class="nm">Layout theme</div>
            <div class="opts_color">

              <div class="opt_color">
                <input type="radio" id="black" :checked="color == 'black'" @change="color = 'black'" name="color" class="inp" value="black">
                <label for="black">Black</label>
                <div class="clr black"></div>
              </div>
              <div class="opt_color">
                <input type="radio" id="white" :checked="color == 'white'" @change="color = 'white'" name="color" class="inp" value="white">
                <label for="white">White</label>
                <div class="clr white"></div>
              </div>
            </div>
          </div>

          <!-- Offical -->
          <div class="pos" data-setup="official">
            <div class="nm">Type of reviews</div>
            <div class="opts_off">
              <div class="opt_off">
                <input type="checkbox" id="official" name="official" :checked="official" @change="official = !official" value="1">
                <label for="official">Show only approved by brand</label>
              </div>

            </div>
          </div>

          <!-- Cover -->
          <div class="pos" data-setup="cover">
            <div class="nm">Photo on reviews</div>
            <div class="opts_off">
              <div class="opt_off">
                <input type="checkbox" id="cover" name="cover" :checked="cover" @change="cover = !cover" value="1">
                <label for="cover">Show covers</label>
              </div>

            </div>
          </div>
          <!-- TARGET -->
          <div class="pos" data-setup="bank">
            <div class="nm">Strain name</div>

            <GeneralSearchKeywordSuggest
              @choose="chooseTagBrand"
              :val="selectedItem.name"
              query_type="brands-seed" 
            />  
          </div>


          <!-- Type -->
          <div class="pos" data-setup="type">
            <div class="nm">Widget type</div>
            <div class="opts_type">
              <div class="opt_type">
                <input type="radio" id="reviews" :checked="type == 'reviews'" @change="type = 'reviews'" name="type" class="inp" value="reviews">
                <label for="reviews">Reviews + Rating</label>
              </div>
              <div class="opt_type">
                <input type="radio" id="rating" :checked="type == 'rating'" @change="type = 'rating'" name="type" class="inp" value="rating">
                <label for="rating">Rating</label>
              </div>
            </div>
          </div>


        </div>
        <div class="cl">

          <!-- STARS -->
          <div class="pos" data-setup="star">
            <div class="nm">Show reviews with</div>
            <div class="opts_star">

              <div 
                class="opt_star" 
                v-for="(r) in [1,2,3,4,5,6,7,8,9,10]"
                >
                <input type="checkbox" name="star" :checked="r <= star"  @change="star = star >=r ? r-1 : r">

                <div class="stars">
                  <img v-for="(rr) in range(1,r)" src="/images/widget/star.png" alt="">
                  <img v-if="r<10" v-for="(rr) in range(1,10-r)" src="/images/widget/starg.png" alt="">
                </div>
                <div class="hnt">
                {{ r }}
                -
                {{ $constants.gradStars[Math.ceil(r)] }}
                </div>
                
              </div>

            </div>
          </div>

        </div>
      </div>



    </div>


    <div class="box_light">
      <div class="ttl">
        Widget preview
      </div>
    </div>

    <!--  -->
    <div class="frame_cont white">
        <ClientOnly>
      <iframe class="frame_box" :src="linkFrame"
        allowtransparency="true" frameborder="0" scrolling="no" tabindex="0" title="GrowDiaries" horizontalscrolling="no"
        ></iframe>
        </ClientOnly>
    </div>
    <!--  -->


    <div class="box_light">
      <div class="ttl">
        Copy embed code
      </div>


      <textarea v-if="type == 'rating' " class="widget_code">
        <div id="growdiaries_rating_container"></div> 
        <script>
            /* * * CONFIGURATION VARIABLES * * */ 
          var growdiaries_bankname = '{{ selectedItem.brand_section }}';
          var growdiaries_seedname = '{{ selectedItem.section }}';
          var growdiaries_color = '{{ color }}';      
          /* * * DON'T EDIT BELOW THIS LINE * * */ 
          (function() { var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true; dsq.src = 'https://bucket.growdiaries.com/web/widget/rating/growdiaries.js'; (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq); })(); 
        </script> 
      </textarea>

      <textarea v-else-if="type == 'reviews' " class="widget_code">
        <div id="growdiaries_reviews_container"></div> 
        <script>
            /* * * CONFIGURATION VARIABLES * * */ 
          var growdiaries_bankname = '{{ selectedItem.brand_section }}';
          var growdiaries_seedname = '{{ selectedItem.section }}';
          var growdiaries_color = '{{ color }}';
          var growdiaries_official = '{{ official ? 1 : 0 }}';
          var growdiaries_star = '{{ star }}';
          var growdiaries_type = '{{ type }}';
          var growdiaries_cover = '{{ cover ? 1 : 0 }}';
          /* * * DON'T EDIT BELOW THIS LINE * * */ 
          (function() { var dsq = document.createElement('script'); dsq.type = 'text/javascript'; dsq.async = true; dsq.src = 'https://bucket.growdiaries.com/web/widget/reviews/growdiaries.js'; (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(dsq); })(); 
        </script>  
      </textarea>


    </div>



    <!--  DESC -->

    <div class="box" refc="1">

      <div class="lf">
        <h2 class="bttl">About the Widget</h2>
        <div class="desc">
          <p>
            This flexible carousel of reviews represents stories shared by our growers and your customers. We would like
            to share these amazing experiences with you!
          </p>
          - get non-cliclable TrustBox<br />
          - diaplay 15 latest reviews<br />
          - convince customers to purchase<br />
          - use adaptable themes for your website design<br />
        </div>
        <div class="pscr">
          **creatives will be interchanging with every page reload
        </div>
      </div>

      <div class="rg">

        <div class="mg">
          <span class="zm" href="/images/widget/b1.jpg">
            <img src="/images/widget/b1.jpg" alt="" class="gl">
          </span>
        </div>

      </div>

    </div>
 

  </div>
  
</template>



<script setup>

const color = ref('white')
const official = ref(false)
const cover = ref(false)
const type = ref('reviews')
const star = ref(10)

const selectedItem = ref({
  section:  "fastbuds",
  name:  "Fast Buds",
})

const linkFrame = computed(() => {
  return 'https://growdiaries.com/external/' + type.value + '?bank=' + selectedItem.value.section + '&color=' + color.value 
})

const widgetCode = computed(() => {
  return '';
})

const range = function (start, end) {
  let array = [];
  for (let i = start; i <= end; i++) {
    array.push(i);
  }
  return array;
}

const chooseTagBrand = function (item) {
  selectedItem.value.brand_name = item.brand_name;
  selectedItem.value.brand_section = item.brand_section;
  selectedItem.value.name = item.name;
  selectedItem.value.section = item.section;
}

</script>



<style scoped>


.wdg {
  /* font-weight: bold; */
}

.wdg a {
  font-weight: bold;
  cursor: pointer;
}

.wdg .hd {
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/images/widget/hero_bg.gif');
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  /* padding-bottom: 5rem; */
}

.wdg .hd .nf {
  width: 500px;
}

.wdg .hd .nf .hnt {
  font-size: 1.25rem;
  font-weight: bold;
}

.wdg .hd .nf .ttl {
  font-size: 3.4rem !important;
  line-height: 3.4rem;
  margin-top: 1rem;
}

.wdg .hd .nf .ttl span {
  color: #2a8758 !important;
}

.wdg .hd .psn {
  width: 260px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.frame_cont {
  padding: 1rem 0;
}
.frame_cont.black {
  background-color: black;
}
.frame_cont.white {
  background-color: white;
}

.frame_cont .frame_box {
  width: 100%;
  height: 350px;
}

@media (max-width: 768px) {
  .wdg .hd {
    display: flex;
    flex-direction: column;
  }

  .wdg .hd .nf {
    width: auto;
    text-align: center;
    margin-top: 20px;
  }

  .wdg .hd .nf .ttl {
    font-size: 2.3rem !important;
    line-height: 2.3rem;
  }
}



/* FORM */


.widget_form {
  display: flex;
  width: 100%;
  max-width: 850px;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  margin-top: -2rem;
  z-index: 100;
  border-radius: 10px;
  box-shadow: 0px 0px 15px #00000030;
  padding: 3rem;
  position: relative;
  background-color: white;
  margin-bottom: 3rem;
}

.widget_form .ttl {
  font-size: 1.5rem;
  font-weight: bold;
}

.widget_form .pos {
  margin-top: 1rem;
}

.widget_form .pos .nm {
    font-weight: bold;
}


.widget_form .pos .opts_color {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.widget_form .pos .opts_color .opt_color {
  border: 2px #e0e0e0 solid;
  padding: 5px;
  border-radius: 5px;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.widget_form .pos .opts_color .opt_color .inp{
    margin-right: 7px;
    margin-left: 4px;
}
.widget_form .pos .opts_color .opt_color .clr {
  border: 1px gray solid;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-left: 10px;
}

.widget_form .pos .opts_color .opt_color .clr.black {
  background-color: black;
}

.widget_form .pos .opts_color .opt {}

.widget_form .pos .opts_color .opt .clr {}




.widget_form .pos .opts_type {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

.widget_form .pos .opts_type .opt_type {
  border: 2px #e0e0e0 solid;
  padding: 5px 9px 5px 5px;
  border-radius: 5px;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.widget_form .pos .opts_type .opt_type .inp{
    margin-right: 7px;
    margin-left: 4px;
}
.widget_form .pos .opts_type .opt_type .clr {
  border: 1px gray solid;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-left: 10px;
}

.widget_form .pos .opts_type .opt_type .clr.black {
  background-color: black;
}

.widget_form .pos .opts_type .opt {}

.widget_form .pos .opts_type .opt .clr {}




.widget_form .pos .opts_star {}
.widget_form .pos .stars {
    margin-right: 7px;
    display: flex;
    align-items: center;
}
.widget_form .pos .stars img {
    width: 16px;
    margin-right: 2px;
}

.widget_form .pos .opts_off {
    margin-top: 0.5rem;
}
.widget_form .pos .opt_off {}
 

.widget_form .pos input {
    margin-right: 7px;
}

.widget_form .pos .opts_star .opt_star {
    display: flex;
    align-items: center;
    margin-top: 5px;
    color: gray;
}

.widget_form .pos .opts_star .opt_star .star {}

.widget_form .pos .opts_inp {
    border: 2px #e0e0e0 solid;
    padding: 5px 13px;
    border-radius: 5px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.widget_form .modal_box_container {
  position: relative;
}
.widget_form .rw{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.widget_form .rw .cl{
    width: 48%;
}

@media (max-width: 768px) { 
  .widget_form .rw{
    flex-direction: column;
  }
  .widget_form .pos .opts_star .opt_star{
    flex-wrap: wrap;
  }
  .widget_form .rw .cl { 
      width: 100%;
  }
  .widget_form .pos .hnt{

    width: 100%;
    padding-inline-start: 20px;
  }

  .frame_cont .frame_box{
    height: 390px;
  }

}


/* WIDGET CODE */

.widget_code{
  width: 100%;
}
.widget_code{
  width: 100%;
  border-radius: 5px;
  border: 2px #cecece solid;
  height: 210px;
  padding: 1rem;
  background-color: #f9f9f9;
  font-family: monospace; 
}

@media (max-width: 768px) { 
}


/* TYPES WIDGET */
.wdg.reviews {}

.wdg.rating {}

.wdg.rating .pos[data-setup=official],
.wdg.rating .pos[data-setup=star]{
  pointer-events: none;
  opacity: 0.3;
}
.wdg.rating .frame_cont .frame_box {
  width: 100%;
  height: 49px;
}



@media (max-width: 768px) { 
  .wdg.rating .frame_cont .frame_box {
    width: 100%;
    height: 99px;
}
}

/* BOXES */

.wdg .box {
  display: flex;
  width: 100%;
  max-width: 850px;
  align-items: flex-start;
  margin: 0 auto; 
  margin-top: 5rem;
}

.wdg .box_light {
  display: flex;
  width: 100%;
  max-width: 850px;
  align-items: flex-start;
  margin: 0 auto;
  flex-direction: column;
  margin-top: 5rem;

}

.wdg .box_light .ttl {
  font-size: 2rem !important;
  margin-bottom: 1rem;
  font-weight: bold;
}

.wdg .box .lf {
  width: 50%;
}

.wdg .box .bttl {
  font-size: 2rem !important;
}

.wdg .box .lf .desc {
  margin: 2rem 0;
  line-height: 1.9rem;
}

.wdg .box .lf .ex {
  max-width: 130px;
  vertical-align: text-top;
}

.wdg .box .lf .exp1 {
  max-width: 160px;
  vertical-align: text-top;
  margin-right: 20px;
  width: calc(50% - 20px);
}

.wdg .box .lf .exp2 {
  max-width: 170px;
  vertical-align: text-top;
  width: calc(50% - 20px);
}

.wdg .box .lf .ex2 {
  max-width: 100%;
}

.wdg .box .lf .pscr {
  margin-top: 2rem;
  color: gray;
}

.wdg .box .rg {
  width: 50%;
  margin-top: 5rem;
}

.wdg .box .rg .mg {
  margin-bottom: 1rem;
  max-height: 400px;
  text-align: center;
  margin-top: 1rem;
}

.wdg .box .rg .mg .zm {
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: center; */
}

.wdg .box .rg .mg .zm .gl {
  width: 100%;
  /* height: 100%; */
}

.wdg .box .rg .mg.mb .gl {
  width: auto;
  max-height: 400px;
  max-width: fit-content;
}

.wdg .box .rg .mg .lks {}

.wdg .box .rg .mg .lks a {
  text-decoration: underline;
}


.wdg .box .lf .custom_checks {
  display: flex;
  flex-wrap: wrap;
}

.wdg .box .lf .custom_checks .ck {
  width: 50%;
  padding: 10px 30px 10px 0px;
  display: flex;
  align-items: center;
}

.wdg .box .lf .custom_checks .ck .check {
  color: #2c8759;
  line-height: 1rem;
  margin-right: 10px;
}



@media (max-width: 768px) {
  .wdg .box {
    flex-direction: column;
    padding: 0 2rem;
  }
  .wdg .box_light{
    padding: 0 2rem;
  }

  .wdg .box .lf {

    width: 100%;
  }

  .wdg .box .rg {
    width: 100%;
    margin-top: 1rem;
  }
}
 

</style>
